import React, { useState } from "react";
import { Box, Button, Typography, Link, Grid } from "@mui/material";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import sso from "../../assets/sso.jpg";

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    const loginRequest = {
      scopes: ["User.Read", "openid", "profile", "email"],
    };

    try {
      setLoading(true);
      const response = await instance.loginPopup(loginRequest);

      if (response.account) {
        const data = response.account.name.split(", ");
        const userData = {
          email: response.account.username,
          firstName: data[1] || "",
          lastName: data[0] || "",
        };

        // Assuming you're using Redux
        // dispatch({ type: 'SET_USER', payload: userData });
        navigate("/");
      }
    } catch (error) {
      alert("Authentication failed!");
      console.error("Authentication error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {accounts.length === 0 ? (
        <Grid container sx={{ height: "100vh" }}>
          {/* Left Side - Sign In Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              position: "relative",
            }}
          >
            {/* KEARNEY at Top Left */}
            <Typography
              variant="h4"
              component="h1"
              sx={{
                position: "absolute",
                top: 20,
                left: 20,
                fontWeight: "500",
              }}
            >
              KEARNEY
            </Typography>

            <Box sx={{ textAlign: "center", p: 4 }}>
              <Typography
                variant="h5"
                component="h2"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                Sign In
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Welcome! Please sign in to your account
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mb: 3, width: "10rem" }}
                onClick={handleSignIn}
                disabled={loading}
              >
                Sign In
              </Button>
              <Typography variant="body2" color="textSecondary">
                By clicking on 'Sign In', you confirm to have read the 'Kearney
                Privacy Statement' and provide consent to use your personal
                information like Name and Email ID
              </Typography>
              <Link
                href="#"
                underline="always"
                sx={{ display: "block", mt: 1 }}
              >
                View Privacy Statement
              </Link>
            </Box>
          </Grid>

          {/* Right Side - Image Section with Magic Cube at Center */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${sso})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay for transparency
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                color: "white",
                fontSize: "2rem",
                fontWeight: "bold",
                zIndex: 1, // Ensures it's above the overlay
              }}
            >
              Magic Cube
            </Box>
          </Grid>
        </Grid>
      ) : (
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      )}
    </>
  );
};

export default ProtectedRoute;
