import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
  Co2,
  EnergySavingsLeaf,
  Insights,
  InsightsOutlined,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  FilterCenterFocus,
  TipsAndUpdates,
  NavigateNext,
  ReportProblem,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
  ScatterChart,
  Scatter,
  ZAxis,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";

export const SupplierChartFunc = ({ supplierChartData, spendSummary }) => {
  let parsedData;

  if (typeof supplierChartData === "string") {
    try {
      parsedData = JSON.parse(supplierChartData);
      console.log("Parsed supplierChartData:", parsedData);
    } catch (error) {
      console.error("Error parsing supplierChartData:", error);
      return null; // or some fallback UI
    }
  } else if (
    typeof supplierChartData === "object" &&
    supplierChartData !== null
  ) {
    parsedData = supplierChartData;
  } else {
    console.error("supplierChartData is not a string or an object");
    return null; // or some fallback UI
  }

  const { barChart } = parsedData;

  // Sort data based on spend
  const combinedData = barChart.labels
    .map((label, index) => ({
      name: label,
      "Total Spend": barChart.data[index],
    }))
    .sort((a, b) => b["Total Spend"] - a["Total Spend"]);

  // Extract top 10 suppliers
  const top10Data = combinedData.slice(0, 10);

  // Calculate the total spend of the top 10 suppliers
  const totalSpendTop10 = top10Data.reduce(
    (sum, entry) => sum + entry["Total Spend"],
    0
  );

  // Calculate the total spend of all suppliers (including others)
  const totalSpendAll = combinedData.reduce(
    (sum, entry) => sum + entry["Total Spend"],
    0
  );

  // Calculate the percentage of spend for the top 10 suppliers
  const totalSpendPercentage = (totalSpendTop10 / totalSpendAll) * 100;

  // Calculate suppliers contributing to 80% of spend
  let cumulativeSpend = 0;
  const suppliersFor80Percent = combinedData.filter((supplier) => {
    cumulativeSpend += supplier["Total Spend"];
    return cumulativeSpend <= totalSpendAll * 0.8;
  });

  const formatLargeNumber = (number) => {
    if (number >= 1e9) return Math.round(number / 1e9) + "B"; // Handle billions
    if (number >= 1e6) return Math.round(number / 1e6) + "M"; // Handle millions
    if (number >= 1e3) return Math.round(number / 1e3) + "K"; // Handle thousands
    return Math.round(number); // Handle numbers below 1,000
  };

  const getFirstName = (name) => {
    // Maximum length of text before truncating
    const maxLength = 12; // Adjust this as needed

    // Truncate and add ellipsis if the name is too long
    return name.length > maxLength
      ? `${name.substring(0, maxLength)}...`
      : name;
  };

  const getSpendContribution = (spend) => {
    return ((spend / totalSpendAll) * 100).toFixed(1);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={2}
      justifyContent="center"
    >
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        position="relative"
      >
        <Box sx={{ flexGrow: 1, height: "auto", position: "relative" }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              layout="horizontal"
              data={top10Data}
              margin={{ top: 20, right: 30, left: 40, bottom: 25 }}
            >
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#BF6FE5" />
                  <stop offset="100%" stopColor="#CA698F" />
                </linearGradient>
              </defs>
              <XAxis
                type="category"
                dataKey="name"
                stroke="white"
                tickFormatter={getFirstName}
                tick={{
                  fill: "white",
                  fontStyle: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  textAlign: "center",
                }}
                interval={0}
                axisLine={{ stroke: "white" }}
                tickLine={{ stroke: "white" }}
                width={100}
                axisLine={false}
              >
                <Label
                  value="Top 10 Suppliers"
                  position="bottom"
                  offset={10}
                  style={{
                    fill: "white",
                    fontStyle: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    textAnchor: "middle",
                  }}
                  // offset={-5}
                />
              </XAxis>
              <YAxis
                type="number"
                stroke="white"
                tickFormatter={formatLargeNumber}
                tick={{
                  fill: "white",
                  fontStyle: "Roboto",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  textAlign: "center",
                }}
                axisLine={false}
              >
                <Label
                  value={`Total Spend (in ${
                    spendSummary.spend_summary.currency_value === "EUR"
                      ? "€"
                      : spendSummary.spend_summary.currency_value === "USD"
                      ? "$"
                      : spendSummary.spend_summary.currency_value === "GBP"
                      ? "£"
                      : spendSummary.spend_summary.currency_value === "AUD"
                      ? "A$"
                      : spendSummary.spend_summary.currency_value
                  })`}
                  angle={-90}
                  position="insideLeft"
                  style={{
                    fill: "white",
                    fontStyle: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                  offset={-20}
                  dy={30}
                />
              </YAxis>
              <Tooltip
                formatter={(value, name) => [
                  name === "Total Spend"
                    ? formatLargeNumber(value)
                    : `${Math.round(value)}%`,
                  name,
                ]}
                cursor={{ fill: "transparent" }}
              />
              <Bar
                dataKey="Total Spend"
                fill="url(#colorGradient)"
                barSize={70}
                barCategoryGap="10%"
                radius={[6, 6, 0, 0]}
              >
                {top10Data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill="url(#colorGradient)"
                    style={{
                      cursor: "pointer",
                      transition: "fill 0.3s ease",
                    }}
                  />
                ))}
                <LabelList
                  dataKey="Total Spend"
                  position="insideTop"
                  content={({ x, y, width, height, value }) => (
                    <text
                      x={x + width / 2}
                      y={y + height / 2}
                      fill="#FFF"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fontStyle="Roboto"
                      fontSize="10px"
                    >
                      {`${formatLargeNumber(value)} (${Math.round(
                        (value / totalSpendAll) * 100
                      )}%)`}
                    </text>
                  )}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>

          {/* Details Box in the top right corner */}
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              width: "400px",
              height: "120px",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "8px",
              padding: "10px",
              color: "white",
              fontStyle: "Roboto",
              fontSize: "12px",
              textAlign: "left",
              boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.2)",
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "left",
                marginBottom: "5px",
              }}
            >
              <Insights
                sx={{ mr: 0.5, verticalAlign: "middle", fontSize: "20px" }}
              />
              Top 10 suppliers contribute to {Math.round(totalSpendPercentage)}%
              spend
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              <Insights
                sx={{ mr: 0.5, verticalAlign: "middle", fontSize: "20px" }}
              />
              {suppliersFor80Percent.slice(0, 3).map((supplier, index) => (
                <span key={index}>
                  {supplier.name} (
                  {Math.round(getSpendContribution(supplier["Total Spend"]))}%)
                  {index < 2 ? ", " : ""}
                </span>
              ))}
              {suppliersFor80Percent.length > 3 && (
                <span>
                  {" "}
                  and {suppliersFor80Percent.length - 3} others contribute to
                  80% of spend
                </span>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
