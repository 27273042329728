import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";

const SidebarFixed = ({ setActiveSection, setShowUpload, setSelectedFile }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [progress, setProgress] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [showProgressSidebar, setShowProgressSidebar] = useState(true);

  const navigate = useNavigate();
  const toggleSidebar = () => {
    console.log("clicked");
  };

  const steps = [
    { label: "1. Fetching Company Details", key: "companyDetails" },
    {
      label: "2. Analyzing Spend Patterns",
      key: "spendPatterns",
      subSteps: ["spendSummary", "categorySummary", "supplierSummary"],
    },
    {
      label: "2.1 Generating Spend Summary",
      key: "spendSummary",
      parent: "spendPatterns",
    },
    {
      label: "2.2 Generating Category Summary",
      key: "categorySummary",
      parent: "spendPatterns",
    },
    {
      label: "2.3 Generating Supplier Summary",
      key: "supplierSummary",
      parent: "spendPatterns",
    },
    {
      label: "3. Analyzing category spend",
      key: "categorySpend",
      subSteps: ["topCategories", "genInsights1"],
    },
    {
      label: "3.1 Identifying top categories",
      key: "topCategories",
      parent: "categorySpend",
    },
    {
      label: "3.2 Generating Insights for top categories",
      key: "genInsights1",
      parent: "categorySpend",
    },
    {
      label: "4. Analysing supplier spend",
      key: "supplierSpend",
      subSteps: ["topSuppliers", "gensuppliers1"],
    },
    {
      label: "4.1 Generating insights for top suppliers",
      key: "topSuppliers",
      parent: "supplierSpend",
    },
    {
      label: "4.2 Generating supplier table for top suppliers",
      key: "gensuppliers1",
      parent: "supplierSpend",
    },
  ];

  useEffect(() => {
    const updateProgress = (key, time) => {
      const timer = setTimeout(() => {
        setProgress((prev) => ({ ...prev, [key]: true }));
      }, time);
      return timer; // Return the timer so we can clear it if needed
    };

    const timers = [
      updateProgress("companyDetails", 0),
      updateProgress("spendSummary", 2000),
      updateProgress("categorySummary", 2000),
      updateProgress("supplierSummary", 2000),
      updateProgress("spendPatterns", 2000),
      updateProgress("topCategories", 4000),
      updateProgress("genInsights1", 6000),
      updateProgress("categorySpend", 10000),
      updateProgress("topSuppliers", 12000),
      updateProgress("gensuppliers1", 19000),
    ];

    const hideProgressSidebarTimer = setTimeout(() => {
      setShowProgressSidebar(false);
    }, 15000);

    // Cleanup function to clear all timers if the component unmounts
    return () => {
      timers.forEach(clearTimeout);
      clearTimeout(hideProgressSidebarTimer);
    };
  }, []);

  const isStepComplete = (step) => {
    if (step.subSteps) {
      return step.subSteps.every((subKey) => progress[subKey]);
    }
    return progress[step.key];
  };

  const isMainStepComplete = (mainStep) => {
    if (mainStep.subSteps) {
      return mainStep.subSteps.every((subStepKey) => progress[subStepKey]);
    }
    return progress[mainStep.key];
  };

  const renderIcon = (step) => {
    const isMainComplete = step.subSteps
      ? isMainStepComplete(step)
      : isStepComplete(step);
    const showClockIcon = step.subSteps && !isMainComplete;

    if (showClockIcon) {
      return <AccessTime sx={{ color: "orange" }} />;
    } else if (isMainComplete) {
      return <CheckCircle sx={{ color: "#4CAF50" }} />;
    } else {
      return <RadioButtonUnchecked sx={{ color: "grey" }} />;
    }
  };

  const isAllStepsComplete = () => {
    return steps.every((step) => {
      if (step.subSteps) {
        return step.subSteps.every((subKey) => progress[subKey]);
      }
      return progress[step.key];
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: isExpanded ? "48px" : "48px",
          flexShrink: 0,
          overflow: "hidden",
          "& .MuiDrawer-paper": {
            width: isExpanded ? "48px" : "48px",
            boxSizing: "border-box",
            backgroundColor: "#181921",
            color: "white",
            border: "0px, 1.03px, 0px, 0px",
            borderRight: "1.03px solid #2C2D3C",
            transition: "width 0.3s ease",
            overflow: "hidden",
          },
        }}
      >
        <IconButton
          onClick={toggleSidebar}
          sx={{
            position: isExpanded ? "absolute" : "relative",
            top: isExpanded ? 0 : "auto",
            right: isExpanded ? 0 : "auto",
            width: "34.4px",
            background: "none",
            border: "none",
            fontSize: "30px",
            cursor: "pointer",
            padding: "10px",
            color: "#858699",
            margin: "0 auto",
          }}
        >
          <FaBars size={20} style={{ width: "34.4px", height: "34.4px" }} />
        </IconButton>
        <List>
          {[
            {
              text: "Home",
              icon: <FaHome size={20} style={{ width: "34.4px" }} />,
              section: "welcome",
              action: () => {
                setSelectedFile(null);
                // setShowUpload(false);
                setActiveSection("welcome");
              },
            },
            // {
            //   text: "Upload",
            //   icon: (
            //     <FaUpload
            //       size={20}
            //       style={{ width: "20.4px", height: "20.4px" }}
            //     />
            //   ),
            //   section: "upload spend",
            //   action: () => setActiveSection("upload spend"),
            // },
            {
              text: "Insights",
              icon: <FaChartBar size={20} style={{ width: "34.4px" }} />,
              section: "insights",
              action: () => setActiveSection("insights"),
            },
          ].map((item, index) => (
            <ListItem
              button
              key={item.text}
              onClick={() => setActiveSection(item.section)}
              sx={{
                justifyContent: isExpanded ? "flex-start" : "center",
                py: isExpanded ? 1 : 2,
                backgroundColor:
                  item.text === "Insights" ? "white" : "transparent",
                color: item.text === "Insights" ? "black" : "white",
                "&:hover": {
                  backgroundColor:
                    item.text === "Insights"
                      ? "white"
                      : "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: item.text === "Insights" ? "black" : "#858699",
                  minWidth: isExpanded ? 56 : "auto",
                  mr: isExpanded ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {isExpanded && (
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: item.text === "Insights" ? "black" : "#858699",
                  }}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
      {/* 
        {!isAllStepsComplete() && showProgressSidebar && (
        <ProgressSidebar progress={progress} />
      )} */}
    </div>
  );
};

const ProgressSidebar = ({ progress }) => {
  const steps = [
    { label: "1. Fetching Company Details", key: "companyDetails" },
    {
      label: "2. Analyzing Spend Patterns",
      key: "spendPatterns",
      subSteps: ["spendSummary", "categorySummary", "supplierSummary"],
    },
    {
      label: "2.1 Generating Spend Summary",
      key: "spendSummary",
      parent: "spendPatterns",
    },
    {
      label: "2.2 Generating Category Summary",
      key: "categorySummary",
      parent: "spendPatterns",
    },
    {
      label: "2.3 Generating Supplier Summary",
      key: "supplierSummary",
      parent: "spendPatterns",
    },
    {
      label: "3. Analyzing category spend",
      key: "categorySpend",
      subSteps: ["topCategories", "genInsights1"],
    },
    {
      label: "3.1 Identifying top categories",
      key: "topCategories",
      parent: "categorySpend",
    },
    {
      label: "3.2 Generating Insights for top categories",
      key: "genInsights1",
      parent: "categorySpend",
    },
    {
      label: "4. Analysing supplier spend",
      key: "supplierSpend",
      subSteps: ["topSuppliers", "gensuppliers1"],
    },
    {
      label: "4.1 Identifying top suppliers",
      key: "topSuppliers",
      parent: "supplierSpend",
    },
    {
      label: "4.2 Generating supplier table for top suppliers",
      key: "gensuppliers1",
      parent: "supplierSpend",
    },
  ];

  const isStepComplete = (step) => {
    if (step.subSteps) {
      return step.subSteps.every((subKey) => progress[subKey]);
    }
    return progress[step.key];
  };

  const isMainStepComplete = (mainStep) => {
    if (mainStep.subSteps) {
      return mainStep.subSteps.every((subStepKey) => progress[subStepKey]);
    }
    return progress[mainStep.key];
  };

  const renderIcon = (step) => {
    const isMainComplete = step.subSteps
      ? isMainStepComplete(step)
      : isStepComplete(step);
    const showClockIcon = step.subSteps && !isMainComplete;

    if (showClockIcon) {
      return <AccessTime sx={{ color: "orange" }} />;
    } else if (isMainComplete) {
      return <CheckCircle sx={{ color: "#4CAF50" }} />;
    } else {
      return <RadioButtonUnchecked sx={{ color: "grey" }} />;
    }
  };

  return (
    <Box
      sx={{
        width: "220px",
        backgroundColor:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06))",
        color: "#84858C",
        borderRight: "1px solid #2C2D3C",
        // borderRadius: '15px',
        // height: "100%",
        overflowY: "hidden",
      }}
    >
      <List>
        {steps.map((step, index) => {
          const isComplete = isStepComplete(step);
          const isMainComplete = step.subSteps
            ? isMainStepComplete(step)
            : isComplete;

          // Hide sub-steps if the main step is complete
          if (step.parent && progress[step.parent]) return null;

          return (
            <ListItem key={index} sx={{ pl: step.parent ? 4 : 2 }}>
              <ListItemIcon>{renderIcon(step)}</ListItemIcon>
              <ListItemText
                primary={step.label}
                sx={{
                  "& .MuiListItemText-primary": {
                    color: "white",
                    fontSize: "12px",
                    fontWeight: isComplete ? "bold" : "normal",
                  },
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export { SidebarFixed };
