import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
  Co2,
  EnergySavingsLeaf,
  Insights,
  InsightsOutlined,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  FilterCenterFocus,
  TipsAndUpdates,
  NavigateNext,
  ReportProblem,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
  ScatterChart,
  Scatter,
  ZAxis,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: "white",
  "&.Mui-selected": {
    backgroundColor: "#BE3FF5",
    color: "white",
    "&:hover": {
      backgroundColor: "#BE3FF5",
    },
  },
}));

export const HistogramChart = ({
  chartData,
  categoryChartDataL1,
  spendSummary,
}) => {
  const [dataSource, setDataSource] = useState("chartData");
  const LoadingGif = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
      }}
    >
      <img
        src={ailoader}
        alt="Loading..."
        style={{ width: "30px", height: "30px" }}
      />
    </Box>
  );

  if (
    !chartData ||
    chartData.length === 0 ||
    !categoryChartDataL1 ||
    categoryChartDataL1.length === 0
  ) {
    return (
      <div>
        <LoadingGif />
      </div>
    );
  }
  const handleChange = (event, newDataSource) => {
    if (newDataSource !== null) {
      setDataSource(newDataSource);
    }
  };
  // Process data to format labels
  const processLabels = (data) => {
    return data.map((entry) => {
      const match = entry.name.match(/(\d+)(\D+)/);
      if (match) {
        return {
          ...entry,
          name: `${match[2].charAt(0).toUpperCase() + match[2].slice(1)} (${
            match[1]
          })`,
        };
      }
      return entry;
    });
  };

  // Remove the "Total" bar from the chart data and process labels
  const filteredData = processLabels(
    (dataSource === "chartData" ? chartData : categoryChartDataL1).filter(
      (entry) => entry.name !== "Total"
    )
  );

  const totalValue = filteredData.reduce((sum, entry) => sum + entry.value, 0);

  const formatPercentage = (value) => {
    return ((value / totalValue) * 100).toFixed(0) + "%";
  };

  const formatLargeNumber = (number) => {
    if (number >= 1e9) {
      return Math.round(number / 1e9) + "B"; // Billions
    } else if (number >= 1e6) {
      return Math.round(number / 1e6) + "M"; // Millions
    } else if (number >= 1e3) {
      return Math.round(number / 1e3) + "K"; // Thousands
    } else {
      return number.toString(); // Smaller numbers
    }
  };

  // Add the number of suppliers to the "Others" label
  const othersEntry = filteredData.find((entry) => entry.name === "Others");
  if (othersEntry) {
    othersEntry.name = `Others (${othersEntry.numCategories} categories)`;
  }

  const calculateTop5Percentage = (data, isChartData) => {
    // Filter out the 'Total' entry and sort by value in descending order
    const sortedData = data
      .filter((entry) => !entry.isTotal)
      .sort((a, b) => b.value - a.value);

    // Sum the values of the top 5 categories
    const top5Sum = isChartData
      ? sortedData.slice(0, 5).reduce((sum, entry) => sum + entry.value, 0)
      : sortedData.slice(1, 6).reduce((sum, entry) => sum + entry.value, 0);

    // Find the total value
    const totalEntry = data.find((entry) => entry.isTotal);
    const totalValue = totalEntry ? totalEntry.value : 0;

    // Calculate the percentage
    const percentage = (top5Sum / totalValue) * 100;

    return percentage.toFixed(0);
  };

  const Top5Box = ({ percentage }) => (
    <Box
      sx={{
        position: "absolute",
        top: 10,
        right: 10,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        fontSize: "0.9rem",
      }}
    >
      Top 5 categories cover {percentage}% of the spend
    </Box>
  );

  const currentData =
    dataSource === "chartData" ? chartData : categoryChartDataL1;
  const top5Percentage = calculateTop5Percentage(
    dataSource === "chartData" ? chartData : categoryChartDataL1,
    dataSource === "chartData"
  );

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Box display="flex" justifyContent="center" mb={2}>
        <ToggleButtonGroup
          value={dataSource}
          exclusive
          onChange={handleChange}
          aria-label="data source"
          sx={{ gap: "6px" }} // This adds space between the buttons
        >
          <StyledToggleButton
            value="chartData"
            aria-label="chart data"
            sx={{
              border: "1px solid transparent",
              borderRadius: "4px",
              width: "80px",
              height: "27px",
            }}
          >
            <span style={{ font: "Roboto", fontWeight: 600, fontSize: "12px" }}>
              Level 1
            </span>
          </StyledToggleButton>
          <StyledToggleButton
            value="categoryChartDataL1"
            aria-label="category chart data"
            sx={{
              border: "1px solid transparent",
              borderRadius: "4px",
              width: "80px",
              height: "27px",
            }}
          >
            <span style={{ font: "Roboto", fontWeight: 600, fontSize: "12px" }}>
              Level 2
            </span>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "330px",
          height: "40px",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: "8px",
          padding: "10px",
          color: "white",
          fontStyle: "Roboto",
          fontSize: "12px",
          textAlign: "left",
          boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.2)",
          zIndex: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "left",
            marginBottom: "5px",
          }}
        >
          <Insights
            sx={{ mr: 0.5, verticalAlign: "middle", fontSize: "20px" }}
          />
          Top 5 categories cover {top5Percentage}% of the spend
        </Typography>
        {/* You can add more details here if needed */}
      </Box>
      <ResponsiveContainer
        width="100%"
        height={400}
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BarChart
          data={filteredData}
          margin={{ top: 20, right: 30, left: 40, bottom: 25 }}
        >
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#BF6FE5" stopOpacity={1} />
              <stop offset="100%" stopColor="#CA698F" stopOpacity={1} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="name"
            axisLine={false}
            tick={{
              fill: "white",
              fontWeight: 400,
              fontSize: "12px",
              fontStyle: "Roboto",
            }}
          >
            <Label
              value="Categories"
              position="bottom"
              offset={10}
              style={{
                textAnchor: "middle",
                fill: "white",
                fontWeight: 400,
                fontSize: "12px",
                fontStyle: "Roboto",
              }}
            />
          </XAxis>
          <YAxis
            axisLine={false}
            tick={{
              fill: "white",
              fontWeight: 400,
              fontSize: "12px",
              fontStyle: "Roboto",
            }}
            tickFormatter={formatLargeNumber}
          >
            <Label
              value={`Spend (in ${
                spendSummary.spend_summary.currency_value === "EUR"
                  ? "€"
                  : spendSummary.spend_summary.currency_value === "USD"
                  ? "$"
                  : spendSummary.spend_summary.currency_value === "GBP"
                  ? "£"
                  : spendSummary.spend_summary.currency_value === "AUD"
                  ? "A$"
                  : spendSummary.spend_summary.currency_value
              })`}
              angle={-90}
              position="insideLeft"
              style={{
                fill: "white",
                fontWeight: 400,
                fontSize: "12px",
                fontStyle: "Roboto",
              }}
              offset={-20}
            />
          </YAxis>
          <Tooltip
            formatter={(value) => [formatLargeNumber(value), "Value"]}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey="value"
            fill="url(#colorGradient)"
            barSize={80}
            radius={[6, 6, 0, 0]}
          >
            <LabelList
              dataKey="value"
              position="center"
              formatter={(value) => formatLargeNumber(value)}
              style={{ fill: "white", fontSize: "0.8rem" }}
            />
            <LabelList
              dataKey="value"
              position="top"
              formatter={(value) => formatPercentage(value)}
              style={{ fill: "white", fontSize: "0.8rem" }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
