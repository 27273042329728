import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { height, padding, styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";
import trendUp from "../../assets/trend_up.svg";
import trendDown from "../../assets/trend_down.svg";

export const InfoCard = ({ icon, title, content }) => (
  <Card
    raised
    sx={{
      height: "220px",
      //   width: "max-content",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      background: "#FFFFFF08",
      color: "rgba(255, 255, 255, 0.95)",
      border: "1px solid #2C2D3C",
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px #1018280",
    }}
  >
    <CardContent sx={{ flexGrow: 1 }}>
      <Box display="flex" alignItems="center" mb={2}>
        {React.isValidElement(icon) ? (
          React.cloneElement(icon, {
            style: { marginRight: "8px", ...icon.props.style },
          })
        ) : (
          <span style={{ marginRight: "8px" }}>{icon}</span>
        )}
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontStyle: "Roboto",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20px",
            textAlign: "left",
            color: "white",
          }}
        >
          {title}
        </Typography>
      </Box>
      {content}
    </CardContent>
  </Card>
);
export const SpendContent = ({ spendSummary }) => {
  const yoyIncreaseSpend = spendSummary.spend_summary.yoy_increase_spend;
  const isIncrease = yoyIncreaseSpend !== null && yoyIncreaseSpend >= 0;
  const yoyText = isIncrease ? "increase" : "decrease";
  const YoyArrow = () => (
    <span style={{ lineHeight: "31px" }}>
      <img
        style={{ width: "14px", height: "15px", margin: "3px" }}
        src={isIncrease ? trendUp : trendDown}
      />
    </span>
  );
  const absYoyIncreaseSpend =
    yoyIncreaseSpend !== null ? Math.abs(yoyIncreaseSpend) : 0;

  return (
    <div
      style={{
        background: "#FFFFFF0F",
        padding: "10px",
        borderRadius: "8px",
        height: "150px",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mb: 2,
          color: "rgba(255, 255, 255, 0.95)",
          fontSize: "1rem",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          component="span"
          variant="h4"
          sx={{
            fontStyle: "Roboto",
            fontSize: "36px",
            fontWeight: 600,
            lineHeight: "38px",
            textAlign: "left",
            height: "38px",
          }}
        >
          {spendSummary.spend_summary.currency_value === "EUR"
            ? "€"
            : spendSummary.spend_summary.currency_value === "USD"
            ? "$"
            : spendSummary.spend_summary.currency_value === "GBP"
            ? "£"
            : spendSummary.spend_summary.currency_value === "AUD"
            ? "A$"
            : spendSummary.spend_summary.currency_value}{" "}
          {spendSummary.spend_summary.overall_spend}
        </Typography>
      </Typography>
      <div
        style={{
          background: isIncrease ? "#FF000033" : "#08744333",
          height: "50px",
          borderRadius: "8px",
          color: "white",
          marginTop: "38px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          paddingLeft: "0 5px",
        }}
      >
        <YoyArrow />
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "38px",
            textAlign: "left",
            color: "white",
            borderRadius: "8px",
            paddingLeft: "5px",
          }}
        >
          <span>
            {yoyIncreaseSpend !== null
              ? `${absYoyIncreaseSpend.toFixed(0)}%`
              : "0.00%"}
          </span>{" "}
          <b>{yoyText}</b> in spend between{" "}
          {spendSummary.spend_summary.second_most_recent_year}-
          {spendSummary.spend_summary.most_recent_year}
        </Typography>
      </div>
    </div>
  );
};

export const CategoriesContent = ({ categorySummary }) => {
  const formatNumber = (num) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(2) + " Bn";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2) + " Mn";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2) + " K";
    }
    return num.toString();
  };

  const formatContributionStatement = (statement) => {
    const [emissions, changeStatement] = statement.split(",");
    const formattedEmissions = formatNumber(parseInt(emissions));

    const isIncrease = changeStatement.includes("increase");

    const boldWord = isIncrease ? "increase" : "decrease";

    const formattedChangeStatement = changeStatement.replace(
      boldWord,
      `<b>${boldWord}</b>`
    );

    return [formattedEmissions, formattedChangeStatement, isIncrease];
  };

  const [formattedEmissions, formattedChangeStatement, isIncrease] =
    formatContributionStatement(
      categorySummary.category_summary.contribution_statement
    );
  const YoyArrow = () => (
    <span style={{ lineHeight: "31px" }}>
      <img
        style={{ width: "14px", height: "15px", margin: "3px" }}
        src={isIncrease ? trendUp : trendDown}
      />
    </span>
  );
  return (
    <div
      style={{
        background: "#FFFFFF0F",
        padding: "10px",
        borderRadius: "8px",
        height: "150px",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.94px",
            textAlign: "left",
            color: "white",
          }}
        >
          L1 Categories
        </Typography>
        <Typography
          component="span"
          variant="h4"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "16.94px",
          }}
        >
          {categorySummary.category_summary.num_l1_categories}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.94px",
            textAlign: "left",
            color: "white",
          }}
        >
          L2 Categories
        </Typography>
        <Typography
          component="span"
          variant="h4"
          sx={{ fontStyle: "Roboto", fontSize: "14px", fontWeight: 600 }}
        >
          {categorySummary.category_summary.num_l2_categories}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={1}
      >
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.94px",
            textAlign: "left",
            color: "white",
          }}
        >
          Total Emissions(KgCO<sub>2</sub>)
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "16.94px",
            textAlign: "right",
            color: "white",
          }}
        >
          {formattedEmissions}
        </Typography>
      </Box>
      <Box
        style={{
          background: isIncrease ? "#FF000033" : "#08744333",
          borderRadius: "8px",
          height: "50px",
          color: "white",
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
          alignItems: "center",
          justifyContent: "start",
          padding: "0px 5px",
        }}
      >
        <YoyArrow />
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "12px",
            fontWeight: 500,
            textAlign: "left",
            color: "white",
            borderRadius: "8px",
            paddingLeft: "5px",
            display: "inline",
          }}
          dangerouslySetInnerHTML={{ __html: formattedChangeStatement }}
        />
      </Box>
    </div>
  );
};
export const SupplierContent = ({ supplierSummary }) => {
  const yoyIncreaseSuppliers =
    supplierSummary.supplier_summary.yoy_increase_suppliers;
  const isIncrease = yoyIncreaseSuppliers !== null && yoyIncreaseSuppliers >= 0;
  const yoyText = isIncrease ? "increase" : "decrease";
  const YoyArrow = () => (
    <span style={{ lineHeight: "31px" }}>
      <img
        style={{ width: "14px", height: "15px", margin: "3px" }}
        src={isIncrease ? trendUp : trendDown}
      />
    </span>
  );
  const absYoyIncreaseSuppliers =
    yoyIncreaseSuppliers !== null ? Math.abs(yoyIncreaseSuppliers) : 0;

  return (
    <div
      style={{
        background: "#FFFFFF0F",
        padding: "10px",
        borderRadius: "8px",
        height: "150px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1.5}
      >
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.94px",
            textAlign: "left",
            color: "white",
          }}
        >
          Total Suppliers
        </Typography>
        <Typography
          component="span"
          variant="h4"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "16.94px",
          }}
        >
          {supplierSummary.supplier_summary.total_suppliers}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1.5}
      >
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.94px",
            textAlign: "left",
            color: "white",
          }}
        >
          Suppliers contribute to 80% spend
        </Typography>
        <Typography
          component="span"
          variant="h4"
          sx={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "16.94px",
          }}
        >
          {supplierSummary.supplier_summary.top_80_percent_supplier_count}
        </Typography>
      </Box>
      <div
        style={{
          height: "50px",
          background: isIncrease ? "#FF000033" : "#08744333",
          borderRadius: "8px",
          color: "white",
          marginTop: "32px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          padding: "0 5px",
        }}
      >
        <YoyArrow />
        <Typography
          variant="body2"
          sx={{
            fontStyle: "Roboto",
            fontSize: "12px",
            fontWeight: 500,
            textAlign: "left",
            color: "white",
            borderRadius: "8px",
            paddingLeft: "5px",
            marginTop: "3px",
          }}
        >
          <span>
            {yoyIncreaseSuppliers !== null
              ? `${absYoyIncreaseSuppliers.toFixed(0)}%`
              : "0.00%"}
          </span>{" "}
          <b>{yoyText}</b> in supplier count between{" "}
          {supplierSummary.supplier_summary.second_most_recent_year}-
          {supplierSummary.supplier_summary.most_recent_year}
        </Typography>
      </div>
    </div>
  );
};
