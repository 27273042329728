import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
  Co2,
  EnergySavingsLeaf,
  Insights,
  InsightsOutlined,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  FilterCenterFocus,
  TipsAndUpdates,
  NavigateNext,
  ReportProblem,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
  ScatterChart,
  Scatter,
  ZAxis,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";

export const CarbonChart = ({ carbonEmission, carbonTable, spendSummary }) => {
  // const [carbonTable, setCarbonTable] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  console.log("spend summary is", spendSummary);

  const CustomTooltip = ({ active, payload, spendSummary }) => {
    if (active && payload && payload.length) {
      const { name, order_amount_sum, total_emissions } = payload[0].payload;
      const formatSpend = (amount) => {
        const currencyValue = spendSummary?.spend_summary?.currency_value;
        const currencySymbol =
          currencyValue === "EUR"
            ? "€"
            : currencyValue === "USD"
            ? "$"
            : currencyValue === "GBP"
            ? "£"
            : currencyValue === "AUD"
            ? "A$"
            : currencyValue || "$"; // Default to € since we know it's EUR from the console

        if (amount >= 1e9) {
          return `${currencySymbol}${Math.round(amount / 1e9)}B`;
        } else if (amount >= 1e6) {
          return `${currencySymbol}${Math.round(amount / 1e6)}M`;
        } else {
          return `${currencySymbol}${Math.round(amount)}`;
        }
      };
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#FFFFFF0F",
            padding: "16px",
            border: "1px solid #FFFFFF0F",
            borderRadius: "6px",
          }}
        >
          <p>
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF99",
              }}
            >
              <b>Category:</b>
            </span>{" "}
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF",
              }}
            >
              {name}
            </span>
          </p>
          <p>
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF99",
              }}
            >
              Spend:
            </span>{" "}
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF",
              }}
            >
              {formatSpend(order_amount_sum)}
            </span>
          </p>
          <p>
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF99",
              }}
            >
              Total Emissions:
            </span>{" "}
            <span
              style={{
                fontStyle: "Roboto",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.94px",
                color: "#FFFFFF",
              }}
            >
              {total_emissions.toFixed(2)} KgCo2
            </span>
          </p>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    const categoryList = Object.keys(carbonTable);
    setCategories(categoryList);

    // Set the default selected category once when the component mounts
    if (categoryList.length > 0) {
      setSelectedCategory(categoryList[0]);
    }
  }, [carbonTable]); // Only runs when `carbonTable` changes

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const formatNumber = (value) => {
    if (value >= 1e9) {
      return (value / 1e9).toFixed(1) + " Bn";
    } else if (value >= 1e6) {
      return (value / 1e6).toFixed(1) + " Mn";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(1) + "K";
    } else {
      return value;
    }
  };

  const LoadingGif = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
      }}
    >
      <img
        src={ailoader}
        alt="Loading..."
        style={{ width: "30px", height: "30px" }}
      />
    </Box>
  );

  const { maxEmissions, maxSpend, top3Categories } = useMemo(() => {
    if (!carbonEmission || typeof carbonEmission !== "object") {
      return { maxEmissions: 0, maxSpend: 0, top3Categories: [] };
    }

    const dataEntries = Object.values(carbonEmission);
    const sortedEmissions = [...dataEntries].sort(
      (a, b) => b.total_emissions - a.total_emissions
    );
    const top3 = sortedEmissions.slice(0, 3);

    return {
      maxEmissions: Math.max(
        ...dataEntries.map((item) => item.total_emissions)
      ),
      maxSpend: Math.max(...dataEntries.map((item) => item.order_amount_sum)),
      top3Categories: top3,
    };
  }, [carbonEmission]);

  const isLoading =
    !carbonTable[selectedCategory] ||
    carbonTable[selectedCategory].length === 0;

  const columnHeaders = [
    "Supplier",
    "Scope 1 Emissions\n(metric tons CO<sub>2</sub>e)",
    "Scope 2 Emissions\n(metric tons CO<sub>2</sub>e)",
    "Scope 3 Emissions\n(metric tons CO<sub>2</sub>e)",
    "Net Zero Target",
    "Short term and long term targets",
  ];
  // console.log('top 3 categories', top3Categories);

  if (!carbonEmission || carbonEmission.length === 0) {
    return (
      <div>
        <LoadingGif />
      </div>
    );
  }

  // Update the YAxis label as well
  const currencySymbol =
    spendSummary?.spend_summary?.currency_value === "EUR"
      ? "€"
      : spendSummary?.spend_summary?.currency_value === "USD"
      ? "$"
      : spendSummary?.spend_summary?.currency_value === "GBP"
      ? "£"
      : spendSummary?.spend_summary?.currency_value === "AUD"
      ? "A$"
      : "$"; // Default to € since we know it's EUR from the console
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart margin={{ top: 20, right: 30, left: 40, bottom: 25 }}>
          <XAxis
            type="number"
            dataKey="total_emissions"
            name="Total Emissions"
            tickFormatter={formatNumber}
            domain={[0, maxEmissions]}
            tick={{
              fill: "white",
              fontWeight: 400,
              fontSize: "12px",
              fontStyle: "Roboto",
            }}
            label={{
              value: "Total Emissions (kgCo₂)", // Use the Unicode for subscript 2
              position: "insideBottom",
              offset: -10,
              fill: "white",
              fontWeight: 400,
              fontSize: "12px",
              fontStyle: "Roboto",
            }}
          />
          <YAxis
            type="number"
            dataKey="order_amount_sum"
            name="Order Amount Sum"
            tickFormatter={formatNumber}
            domain={[0, maxSpend]}
            tick={{
              fill: "white",
              fontWeight: 400,
              fontSize: "12px",
              fontStyle: "Roboto",
            }}
          >
            <Label
              value={`Spend (in ${currencySymbol})`}
              angle={-90}
              position="insideLeft"
              style={{
                fill: "white",
                fontWeight: 400,
                fontSize: "12px",
                fontStyle: "Roboto",
              }}
              offset={-20}
              dy={30}
            />
          </YAxis>

          <ZAxis
            type="number"
            dataKey="total_emissions"
            range={[100, 1000]}
            name="Total Emissions (Bubble Size)"
            tick={{
              fill: "white",
              fontWeight: 400,
              fontSize: "12px",
              fontStyle: "Roboto",
            }}
          />
          <Tooltip
            cursor={{ stroke: "none", fill: "transparent" }}
            content={<CustomTooltip />}
          />
          <Legend
            verticalAlign="top"
            height={36}
            align="left"
            wrapperStyle={{ paddingLeft: "40px" }}
          />
          <ReferenceArea
            x1={maxEmissions / 2}
            x2={maxEmissions}
            y1={maxSpend / 2}
            y2={maxSpend}
            fill="#808080"
            fillOpacity={0.2}
          >
            <Label
              content={({ viewBox }) => (
                <text
                  x={viewBox.x + viewBox.width / 2}
                  y={viewBox.y - 20}
                  fill="white"
                  fontSize={12}
                  fontStyle="Roboto"
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  <tspan
                    x={viewBox.x + viewBox.width / 2}
                    dy="-0.6em"
                    fontStyle="italic"
                  >
                    Hotspot Categories
                  </tspan>
                  <tspan
                    x={viewBox.x + viewBox.width / 2}
                    dy="1.2em"
                    fontStyle="italic"
                  >
                    (High Emissions, High Spend)
                  </tspan>
                </text>
              )}
            />
          </ReferenceArea>
          <Scatter
            name={
              <span
                style={{
                  fontStyle: "Roboto",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                Category
              </span>
            }
            data={carbonEmission}
            fill="url(#colorUv)"
            shape={(props) => {
              const { cx, cy, payload } = props;
              const isTop3 = top3Categories.some(
                (category) => category.name === payload.name
              );
              console.log("isTop3", isTop3, "for category", payload.name);
              console.log("top3Categories", top3Categories);
              return (
                <g>
                  <circle
                    cx={cx}
                    cy={cy}
                    r={(payload.total_emissions / maxEmissions) * 30}
                    fill="url(#colorUv)"
                  />
                  {isTop3 && (
                    <text
                      x={cx}
                      y={
                        cy + (payload.total_emissions / maxEmissions) * 30 + 15
                      }
                      textAnchor="middle"
                      fill="white"
                      fontSize="12"
                      fontStyle="Roboto"
                    >
                      {payload.name}
                    </text>
                  )}
                </g>
              );
            }}
          />
          <ReferenceLine
            x={maxEmissions / 2}
            stroke="rgba(255,255,255,0.3)"
            strokeDasharray="3 3"
          >
            <Label
              // value="Middle"
              position="insideTopRight"
              fill="white"
              fontSize={10}
            />
          </ReferenceLine>
          <ReferenceLine
            y={maxSpend / 2}
            stroke="rgba(255,255,255,0.3)"
            strokeDasharray="3 3"
          >
            <Label
              // value="Middle"
              position="insideRightTop"
              fill="white"
              fontSize={10}
              angle={-90}
            />
          </ReferenceLine>
          <ReferenceLine
            x={maxEmissions / 2}
            stroke="rgba(255,255,255,0.3)"
            strokeDasharray="3 3"
          />
          <ReferenceLine
            y={maxSpend / 2}
            stroke="rgba(255,255,255,0.3)"
            strokeDasharray="3 3"
          />

          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#BF6FE5" />
              <stop offset="100%" stopColor="#CA698F" />
            </linearGradient>
          </defs>
        </ScatterChart>
      </ResponsiveContainer>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF08",
          width: "100%",
          padding: "16px",
          gap: "16px",
          border: "1px solid #FFFFFF0F",
          borderRadius: "10px",
          p: 2,
          mt: 2,
        }}
      >
        <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // marginBottom: '0.3rem',
            }}
        >
        <Typography
          variant="h6"
          color="white"
          style={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18px",
            color: "white",
          }}
        >
          <b>Supplier deep dive for focused categories</b>
        </Typography>

        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          sx={{
            maxWidth: "120px",
            width: "auto",
            height: "24px",
            padding: "4px 8px",
            fontStyle: "Roboto",
            fontSize: "12px",
            fontWeight: 500,
            gap: "4px",
            borderRadius: "4px",
            opacity: 1,
            background: "#FFFFFF0F",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #E2E8F00F",
            "& .MuiSelect-select": {
              padding: "0",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          IconComponent={(props) => (
            <ArrowDropDownCircle
              {...props}
              style={{
                color: "white",
                fontSize: "14px",
              }}
            />
          )}
          MenuProps={{
            PaperProps: {
              style: {
                background: "white",
              },
            },
            MenuListProps: {
              sx: {
                fontStyle: "Roboto",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "15px",
              },
            },
          }}
        >
          {categories ? (
            categories.map((category, index) => (
              <MenuItem
                key={category}
                value={category}
                sx={{
                  fontSize: "12px",
                  fontStyle: "Roboto",
                  fontWeight: 500,
                  lineHeight: "15px",
                }}
              >
                {category}
              </MenuItem>
            ))
          ) : (
            <Skeleton variant="text" width="80%" />
          )}
        </Select>
        </Box>
        <Box
          sx={{
            mb: 1,
            maxWidth: "100%",
            overflowX: "auto",
            border: "1px solid #FFFFFF0F",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF0F",
          }}
        >
          <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                {columnHeaders.map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      color: "white",
                      fontStyle: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      width: "20%",
                      padding: "8px",
                      border: "0px, 0px, 1px, 0px",
                      borderBottom: "1px solid #FFFFFF0F",
                      backgroundColor: "#353641B2",
                      textAlign: "left",
                    }}
                  >
                    {header.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        <span dangerouslySetInnerHTML={{ __html: line }} />
                        {index === 0 && <br />}
                      </React.Fragment>
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? Array.from({ length: 5 }).map((_, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Array.from({ length: 6 }).map((_, cellIndex) => (
                        <TableCell
                          key={cellIndex}
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          <Skeleton variant="text" width="100%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : carbonTable[selectedCategory]?.map((supplierData, index) => {
                    const supplierName = Object.keys(supplierData)[0];
                    const supplierDetails = supplierData[supplierName];

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderBottom: "1px solid #FFFFFF0F",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "20px",
                          textAlign: "left",
                          width: "180px",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // lineHeight: "18px",
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {supplierName}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // lineHeight: "18px",
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {supplierDetails["Scope 1 Emissions"] === "N/A" ? (
                            <i>Not disclosed in CDP</i>
                          ) : (
                            formatNumber(supplierDetails["Scope 1 Emissions"])
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // lineHeight: "18px",
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {supplierDetails["Scope 2 Emissions"] === "N/A" ||
                          "None" ? (
                            <i>Not disclosed in CDP</i>
                          ) : (
                            formatNumber(supplierDetails["Scope 2 Emissions"])
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // lineHeight: "18px",
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {supplierDetails["Scope 3 Emissions"] === "N/A" ? (
                            <i>Not disclosed in CDP</i>
                          ) : (
                            formatNumber(supplierDetails["Scope 3 Emissions"])
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // lineHeight: "18px",
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {Array.isArray(supplierDetails["Net Zero Targets"]) &&
                          supplierDetails["Net Zero Targets"].length > 0 &&
                          supplierDetails["Net Zero Targets"].some(
                            (target) => target !== "N/A"
                          ) ? (
                            supplierDetails["Net Zero Targets"]
                              .filter((target) => target !== "N/A")
                              .map((target, index) => (
                                <div key={index}>{target}</div>
                              ))
                          ) : (
                            <i>Not disclosed in CDP</i>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // lineHeight: "18px",
                            color: "white",
                            width: "20%",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          {supplierDetails["Scope Targets"] ? (
                            (() => {
                              const validTargets = Object.entries(
                                supplierDetails["Scope Targets"]
                              ).filter(([scope, target]) => target !== "N/A");

                              if (validTargets.length === 0) {
                                return <i>Not disclosed in CDP</i>;
                              }

                              return validTargets.map(
                                ([scope, target], index) => (
                                  <div key={index}>{formatNumber(target)}</div>
                                )
                              );
                            })()
                          ) : (
                            <i>Not disclosed in CDP</i>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </>
  );
};
